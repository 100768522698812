import { createReducer, on } from '@ngrx/store';

import * as notificationActions from '@notifications/store/actions/notifications.actions';
import { NotificationsState } from '@notifications/store/states/notifications.state';
import { NotificationsService } from '../services/notifications.service';
import * as settingsActions from '@settings/store/actions/settings.actions';

const initialState = new NotificationsState();

export const notificationsReducer = createReducer(
    initialState,
    on(notificationActions.setNotifications, (state, { notifications }) => ({ ...state, notifications })),
    on(notificationActions.setActiveTabIndex, (state, { index }) => ({ ...state, activeTabIndex: index })),
    on(notificationActions.showOnlyUnviewedFilterChanged, (state) => ({ ...state, isShowOnlyUnviewedNotifications: !state.isShowOnlyUnviewedNotifications })),
    on(notificationActions.viewUnviewNotifications, notificationActions.viewUnviewNotificationsFailed, (state, { notificationIds }) => {
        return { ...state, notifications: NotificationsService.viewUnviewNotifications(state.notifications, notificationIds) };
    }),
    on(notificationActions.markAsReadNotifications, (state, { notificationIds }) => {
        return { ...state, notifications: NotificationsService.markAsReadNotifications(state.notifications, notificationIds) };
    }),
    on(notificationActions.resetState, () => ({ ...initialState })),
    on(notificationActions.setAppointmentsVisualizationAllowed, (state, { isAllowed }) => ({ ...state, appointmentsVisualizationAllowed: isAllowed })),
    on(settingsActions.loadSettingsSuccessful, (state, { layoutSettings }) => ({ ...state, isShowOnlyUnviewedNotifications: layoutSettings.showOnlyUnreadNotifications })),
);