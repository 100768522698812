import { Injectable } from '@angular/core';
import * as moment from 'moment';
import groupBy from 'lodash-es/groupBy';

import { NotificationEventEntity } from '@notifications/enums/notification-event-entity';
import { NotificationEventAction } from '@notifications/enums/notification-event-action';
import { NotificationApiItem } from '@notifications/models/notification-api-item';
import { UsersService } from '@users/services/users.service';
import { NotificationAttributesService } from './notification-attributes.service';
import { UserBaseInfo } from '@users/models/user-base-info';
import { Creator } from '@appointments/enums/creator.enum';
import { APPOINTMENT_STATUS_MAP } from '@notifications/constants/graphql-types-map.constants';
import { CommentNotification, ListingNotification, AppointmentNotification } from '@notifications/models/notification-data';
import { NotificationGroupItem } from '@notifications/models/notification-group-item';
import { NotificationPlainItem } from '@notifications/models/notification-plain-item';
import { NotificationItem, Notifications } from '@notifications/models/notifications';
import { StoreNotification } from '@notifications/models/store-notification';
import { CLUSTER_BY_NOTIFICATION_ACTION, NotificationActionsToShow } from '@notifications/constants/notifications.constants';
import { Counters } from '@notifications/models/counters';
import { AppointmentStatus } from '@appointments/enums/appointment-status.enum';
import { DateConstants } from '@core-constants/dates/date.constants';

type NotificationGroups = { added: NotificationPlainItem[], liked: NotificationPlainItem[], disliked: NotificationPlainItem[], shown: NotificationPlainItem[], nonGrouped: NotificationPlainItem[] };

@Injectable({ providedIn: 'root' })
export class NotificationsService {
    public static getAllNotificationsItemsGroupedByTimePeriod
        (notifications: StoreNotification[],
            customersAndAgents: UserBaseInfo[],
            isShowOnlyUnreadNotifications: boolean
        ): Notifications {
        const filteredNotifications = isShowOnlyUnreadNotifications ? notifications.filter(x => !x.isViewed) : notifications;
        const notificationsGroupedByDay = groupBy(filteredNotifications, x => moment(x.dateCreated).startOf('day'));

        const groupItemsCount = 1;

        return Object.entries(notificationsGroupedByDay).reduce((notifications: Notifications, [date, notificationsByDay]: [string, StoreNotification[]]) => {
            return NotificationsService.groupNotificationsByTimePeriod(notificationsByDay, customersAndAgents, notifications, groupItemsCount, date);
        }, { today: [], yesterday: [], older: [] } as Notifications);
    }

    public static getNotificationCounters(notifications: StoreNotification[]): Counters {
        const actionKeyMap = new Map<NotificationEventEntity, keyof Counters>([
            [NotificationEventEntity.Comment, 'messages'],
            [NotificationEventEntity.Appointment, 'appointments'],
            [NotificationEventEntity.ListingActivity, 'listings'],
            [NotificationEventEntity.ExternalListing, 'listings'],
        ]);

        return notifications.reduce((counters, notification) => {
            const key = actionKeyMap.get(notification.type);

            return key != null && !notification.isViewed ? { ...counters, [key]: counters[key] + 1 } : counters;
        }, { messages: 0, appointments: 0, listings: 0 } as Counters);
    }

    public static filterNotificationsByType(allNotitifications: Notifications, ...types: NotificationEventEntity[]): Notifications {
        const filterCollection = (notifications: NotificationItem[]) => {
            return notifications.filter(x => Array.isArray(types) ? types.includes(x.type) : x.type === types[0]);
        };

        return {
            today: filterCollection(allNotitifications.today),
            yesterday: filterCollection(allNotitifications.yesterday),
            older: filterCollection(allNotitifications.older),
        };
    }

    public static mapToNotificationBaseModel(notification: NotificationApiItem, customerId: number, agentId: number): StoreNotification {
        const { collaborationId, entityType, comment, appointment, listing, ...commonFields } = notification;

        const data = entityType === NotificationEventEntity.Comment
            ? { text: comment.text } as CommentNotification
            : entityType === NotificationEventEntity.Appointment
                ? NotificationsService.createAppointmentNotification(notification, customerId, agentId)
                : { listingUrl: listing.imageUrl } as ListingNotification;

        return { ...commonFields, type: entityType, listingAddress: listing?.address, dateCreated: notification.createDate, data };
    }

    public static createNotificationPlainItem(notification: StoreNotification, customersAndAgents: UserBaseInfo[]): NotificationPlainItem {
        const { listingAddress } = notification;

        const creator = UsersService.getUserInfoOrRemovedAccount(customersAndAgents, notification.createId);
        const { title, params, icon, iconClass } = NotificationAttributesService.getForSingle(notification.action, listingAddress, creator.fullName);

        return { ...notification, isGroup: false, translationInfo: { key: title, params }, icon, iconClass, creator };
    }

    public static getNotificationsWithoutOutdated(notifications: StoreNotification[]): StoreNotification[] {
        notifications.sort((a, b) => moment(a.dateCreated).isSameOrAfter(b.dateCreated) ? 1 : -1);

        const notificationByClusterMap = notifications.reduce(
            (clustersMap, x) => clustersMap.set(`${x.entityId}-${CLUSTER_BY_NOTIFICATION_ACTION.get(x.action as NotificationActionsToShow)}`, x),
            new Map<string, StoreNotification>()
        );

        return [...notificationByClusterMap.values()];
    }

    public static viewUnviewNotifications(notifications: StoreNotification[], notificationIds: number[]): StoreNotification[] {
        const ids = new Set(notificationIds);

        return notifications.map(notification => {
            return { ...notification, isViewed: ids.has(notification.notificationId) ? !notification.isViewed : notification.isViewed };
        });
    }

    public static markAsReadNotifications(notifications: StoreNotification[], notificationIds: number[]): StoreNotification[] {
        const ids = new Set(notificationIds);

        return notifications.map(notification => {
            return { ...notification, isViewed: ids.has(notification.notificationId) ? true : notification.isViewed };
        });
    }

    public static getNotificationIdsByViewed(notifications: (StoreNotification | NotificationPlainItem)[], isViewed: boolean): number[] {
        return notifications.reduce((ids, x) => x.isViewed === isViewed ? [...ids, x.notificationId] : ids, new Array<number>());
    }

    public static updateAppointmentCreatedNotification(
        notifications: StoreNotification[],
        appointmentId: number,
        previousCustomerStatus: AppointmentStatus,
        newCustomerStatus: AppointmentStatus,
        updateId: number,
        agentId: number
    ): StoreNotification[] {
        const updatedNotifications = [...notifications];
        const index = updatedNotifications.findIndex(x => {
            return x.entityId === appointmentId && (x.action === NotificationEventAction.AppointmentCreated || x.action === NotificationEventAction.AppointmentUpdated);
        });
        const notificationToUpdate = updatedNotifications[index];

        const { startDateTime, creator, agentStatus, customerStatus: currentCustomerStatus, endDateTime, comment } = notificationToUpdate.data as AppointmentNotification;
        const { createId } = notificationToUpdate;
        const customerStatus = currentCustomerStatus === newCustomerStatus ? previousCustomerStatus : newCustomerStatus;

        const appointmentOptions = { customerStatus, agentStatus, creator, createId, updateId, customerId: updateId, agentId, startDateTime, endDateTime, comment };
        const data = { ...updatedNotifications[index].data, ...appointmentOptions, updateId } as AppointmentNotification;

        updatedNotifications.splice(index, 1, { ...updatedNotifications[index], data });

        return updatedNotifications;
    }

    private static createAppointmentNotification(notification: NotificationApiItem, customerId: number, agentId: number): AppointmentNotification {
        const { createId, updateId, comment, isCreatedByAgent } = notification.appointment;

        const creator = isCreatedByAgent ? Creator.Agent : Creator.Customer;
        const customerStatus = APPOINTMENT_STATUS_MAP.get(notification.appointment.customerStatus);
        const agentStatus = APPOINTMENT_STATUS_MAP.get(notification.appointment.agentStatus);
        const startDateTime = moment(notification.appointment.startDateTime, DateConstants.Formats.IsoWithoutTimezoneOffset).toDate();
        const endDateTime = moment(notification.appointment.endDateTime, DateConstants.Formats.IsoWithoutTimezoneOffset).toDate();

        return { customerStatus, agentStatus, creator, createId, updateId, customerId, agentId, startDateTime, endDateTime, comment };
    }

    private static groupNotificationsByTimePeriod(notificationsByDay: StoreNotification[], customersAndAgents: UserBaseInfo[], notifications: Notifications, groupsCount: number, date: string) {
        const { added, liked, disliked, shown, nonGrouped } = NotificationsService.groupNotiticationsByListingAction(notificationsByDay, customersAndAgents);

        const notificationItems = [added, liked, disliked, shown].flatMap(x => NotificationsService.tryCreateNotificationGroupItem(x, customersAndAgents, groupsCount));

        const getItemDate = (item: NotificationPlainItem | NotificationGroupItem) => (item as NotificationPlainItem).dateCreated ?? (item as NotificationGroupItem).date;
        const timePeriodNotifications = [...notificationItems, ...nonGrouped];

        timePeriodNotifications.sort((a, b) => moment(getItemDate(a)).isSameOrAfter(getItemDate(b)) ? -1 : 1);

        const timePeriodKey = NotificationsService.getNotificationTimePeriodKey(date);

        return { ...notifications, [timePeriodKey]: [...notifications[timePeriodKey], ...timePeriodNotifications] };
    }

    private static groupNotiticationsByListingAction(notificationsByDay: StoreNotification[], customersAndAgents: UserBaseInfo[]): NotificationGroups {
        const actionKeyMap = new Map<NotificationEventAction, Exclude<keyof NotificationGroups, 'nonGrouped'>>([
            [NotificationEventAction.ListingActivityAddedToPortfolio, 'added'],
            [NotificationEventAction.ListingActivityLiked, 'liked'],
            [NotificationEventAction.ListingActivityDisliked, 'disliked'],
            [NotificationEventAction.ListingActivityShown, 'shown']
        ]);

        return notificationsByDay.reduce((notitifcationsByAction, notificationBase) => {
            const key: keyof NotificationGroups = actionKeyMap.get(notificationBase.action) ?? 'nonGrouped';
            const notificationItem = NotificationsService.createNotificationPlainItem(notificationBase, customersAndAgents);

            return { ...notitifcationsByAction, [key]: [...notitifcationsByAction[key], notificationItem] };
        }, { added: [], liked: [], disliked: [], shown: [], nonGrouped: [] } as NotificationGroups);
    }

    private static tryCreateNotificationGroupItem(notificationsToGroup: NotificationPlainItem[], customersAndAgents: UserBaseInfo[], groupsCount: number): NotificationItem[] {
        notificationsToGroup.sort((a, b) => moment(a.dateCreated).isSameOrAfter(b.dateCreated) ? -1 : 1);

        const groupedNotificationsByCreator = notificationsToGroup.reduce((notificationsByCreator, item) => {
            return notificationsByCreator.set(item.creator.customerId, [...(notificationsByCreator.get(item.creator.customerId) ?? []), item]);
        }, new Map<number, NotificationPlainItem[]>());

        return notificationsToGroup.length > 1
            ? [NotificationsService.createGroup(notificationsToGroup, groupedNotificationsByCreator, customersAndAgents, groupsCount)]
            : notificationsToGroup;
    }

    private static createGroup(
        notificationsToGroup: NotificationPlainItem[],
        groupedNotificationsByCreator: Map<number, NotificationPlainItem[]>,
        customersAndAgents: UserBaseInfo[],
        groupItemsCount: number
    ): NotificationGroupItem {
        const { type, action } = notificationsToGroup[0];
        const { title, params, icon, iconClass } = NotificationAttributesService.getForGroup(action, notificationsToGroup.length);
        const isViewed = notificationsToGroup.every(x => x.isViewed);
        const id = `group_${groupItemsCount++}`;

        const groups = [...groupedNotificationsByCreator.entries()].map(([createId, notifications]) => {
            return { creator: UsersService.getUserInfoOrRemovedAccount(customersAndAgents, createId), notifications, isViewed: notifications.every(x => x.isViewed) };
        });

        const date = groups.reduce(
            (lastNotificationDate, x) => moment(x.notifications[0].dateCreated).isAfter(lastNotificationDate)
                ? x.notifications[0].dateCreated
                : lastNotificationDate,
            groups[0].notifications[0].dateCreated
        );

        return { icon, iconClass, type, action, date, isGroup: true, isViewed, groups, notifitionsCount: notificationsToGroup.length, translationInfo: { key: title, params }, id };
    }

    private static getNotificationTimePeriodKey(date: string): keyof Notifications {
        return moment().startOf('day').isSame(moment(new Date(date)), 'day')
            ? 'today'
            : moment().subtract(1, 'days').startOf('day').isSame(moment(new Date(date)), 'day')
                ? 'yesterday'
                : 'older';
    }
}
