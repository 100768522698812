import { Injectable, Injector } from '@angular/core';
import { ReadyMessage } from '../models/ready-message';
import { HybridService } from '../hybrid.service';
import { TokenInitializerService } from '@auth/services/token-initializer.service';
import { Router } from '@angular/router';
import { MessageHandler } from './message-handler';
import { DynamicLinkService } from '@core-layout/app/dynamic-link.service';
import { UserStoreService } from '@auth/store/services/user-store.service';
import { filter, switchMap, take } from 'rxjs/operators';
import { tap } from 'rxjs';
import { SplashScreenService } from '@core-services/splash-screen.service';

@Injectable({
    providedIn: 'root'
})
export class ReadyMessageHandler implements MessageHandler<ReadyMessage> {

    constructor(
        private readonly hybridService: HybridService,
        private readonly injector: Injector,
        private readonly splashScreenService: SplashScreenService,
        private readonly graphqlLinkService: DynamicLinkService,
        private readonly tokenInitializerService: TokenInitializerService,
        private readonly userStoreService: UserStoreService,
        private readonly router: Router
    ) { }

    public handle(message: ReadyMessage): void {
        this.hybridService.isHybrid$.next(true);
        const baseApiUrl = this.injector.get('BASE_API_URL') as { url: string };
        const notificationsApiUrl = this.injector.get('NOTIFICATIONS_API_URL') as { url: string };

        baseApiUrl.url = message.baseApiUrl;
        this.graphqlLinkService.setUri(message.gatewayUrl);
        notificationsApiUrl.url = message.notificationsUrl;

        this.splashScreenService.forceHide(0);
        this.tokenInitializerService
            .refreshToken()
            .pipe(
                switchMap(() => this.userStoreService.getIsAuthorized()),
                filter(isAuthorized => isAuthorized),
                switchMap(() => this.userStoreService.getUser()),
                filter(user => user != null),
                take(1),
                tap(() => {
                    this.hybridService.isReady$.next(true);
                })
            )
            .subscribe();
    }
}

